import React from "react"
import * as styles from "./centeredText.module.scss"

function centeredText({ data }) {

  return (
    <div className={styles.titleText}>
      <div dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
    </div>
  )

}

export default centeredText