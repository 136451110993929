import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/layout/seo"
import Hero from "../components/Global/hero/hero"
import CenteredText from "../components/Global/centeredText/centeredText"
import Ctas from "../components/Global/callToActions/callToActions"
import FullWidthImage from "../components/Global/fullWidthImage/fullWidthImage"
import VideoBanner from "../components/Global/videoBanner/videoBanner"
import Footnotes from "../components/Global/footnotes/footnotes"
import Footer from "../components/layout/footer"

const centerButtonStyles = {
  gridColumn: "2/12"
}

const Homepage = ({ data }) => {

  const content = data.allContentfulHomepage.nodes[0];

  let heroData = {
    backgroundImage: content.heroBackgroundImage,
    backgroundTitle: content.heroBackgroundImage.title,
    text: content.heroText,
  }

  let introductionTextData = content.introductionText;
  let callToActionTitleData = content.callToActionTitle;
  let callToActionsData = content.callToActions;

  let fullWidthImage1Data = {
    imageTitle: content.fullWidthBanner.image.title,
    image: getImage(content.fullWidthBanner.image),
  }

  let fullWidthImage2Data = {
    imageTitle: content.fullWidthBanner2.image.title,
    image: getImage(content.fullWidthBanner2.image),
  }

  let videoBannerData = {
    text: content.videoBanner.content,
    buttonText: content.videoBanner.buttonText,
    buttonDestination: content.videoBanner.buttonDestination,
    videoUrl: content.videoBanner.videoUrl,
    showButton: true,
  }

  let outroTextData = content.outroText.text;

  let ButtonData = {
    slug: content.outroButtonDestination,
    text: content.outroButtonText
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: content.footerData,
  }


  return (
    <Layout>
      <Seo title="Home" />
      <Hero data={heroData} />
      <CenteredText data={introductionTextData} />
      <CenteredText data={callToActionTitleData} />
      <Ctas data={callToActionsData} />
      <FullWidthImage data={fullWidthImage1Data} />
      <VideoBanner data={videoBannerData} />
      <FullWidthImage data={fullWidthImage2Data} />
      <CenteredText data={outroTextData} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulHomepage {
      nodes {
        heroBackgroundImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
          )
          title
        }
        heroText {
          childMarkdownRemark {
            html
          }
        }
        introductionText {
          childMarkdownRemark {
            html
          }
        }
        callToActionTitle {
          childMarkdownRemark {
            html
          }
        }
        callToActions {
          buttonText {
            childMarkdownRemark {
              html
            }
          }
          buttonDestination
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        fullWidthBanner {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        videoBanner {
          content {
            childMarkdownRemark {
              html
            }
          }
          buttonText {
            childMarkdownRemark {
              html
            }
          }
          buttonDestination
          videoUrl
        }
        fullWidthBanner2 {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }          
        }
        outroText {
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`

export default Homepage