import React from "react"
import * as styles from "./hero.module.scss"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

function Hero(props) {

  const image = getImage(props.data.backgroundImage)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <div className={styles.banner}>
      <BackgroundImage className={styles.banner__background}
        {...bgImage}
        preserveStackingContext
        alt={props.data.backgroundImage}
        style={{
          backgroundPosition: 'bottom right'
        }}>

        <div className={styles.banner__content}>
          <div dangerouslySetInnerHTML={{ __html: props.data.text.childMarkdownRemark.html }} />
        </div>

      </BackgroundImage>

      <div className={styles.banner__content__mobile}>
        <div dangerouslySetInnerHTML={{ __html: props.data.text.childMarkdownRemark.html }} />
      </div>
    </div>
  )
}

export default Hero