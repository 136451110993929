// extracted by mini-css-extract-plugin
export var active = "centeredText-module--active--e6f75";
export var bigWhite = "centeredText-module--big-white--569cd";
export var blue = "centeredText-module--blue--f7d1e";
export var blueBg = "centeredText-module--blue-bg--9e5f8";
export var center = "centeredText-module--center--d2213";
export var cookie__customise = "centeredText-module--cookie__customise--e2689";
export var cookie__customise__buttons = "centeredText-module--cookie__customise__buttons--318da";
export var cookie__customise__buttons__allow = "centeredText-module--cookie__customise__buttons__allow--003f7";
export var cookie__customise__buttons__disable = "centeredText-module--cookie__customise__buttons__disable--87e74";
export var cookie__customise__buttons__save = "centeredText-module--cookie__customise__buttons__save--c044a";
export var cookie__customise__close = "centeredText-module--cookie__customise__close--5968f";
export var cookie__customise__content = "centeredText-module--cookie__customise__content--810fc";
export var cookie__customise__content__title = "centeredText-module--cookie__customise__content__title--369e0";
export var cookie__customise__overlay = "centeredText-module--cookie__customise__overlay--90fb1";
export var cookie__popup = "centeredText-module--cookie__popup--e18b4";
export var cookie__popup__button = "centeredText-module--cookie__popup__button--f1eeb";
export var cookie__popup__close = "centeredText-module--cookie__popup__close--7bcb3";
export var cookie__popup__content = "centeredText-module--cookie__popup__content--80bc4";
export var cookie__popup__text = "centeredText-module--cookie__popup__text--d623b";
export var decreaseHeight = "centeredText-module--decrease-height--7064d";
export var embed_responsive = "centeredText-module--embed_responsive--720d5";
export var embed_responsive_16by9 = "centeredText-module--embed_responsive_16by9--390ce";
export var embed_responsive_item = "centeredText-module--embed_responsive_item--abde6";
export var flexUl = "centeredText-module--flex-ul--9fc6a";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "centeredText-module--fullWidthCta-module--fullWidthCta--fdc73--3b6aa";
export var fullWidthTextModuleIntroText2b0e4 = "centeredText-module--fullWidthText-module--introText--2b0e4--5e830";
export var header = "centeredText-module--header--36d2d";
export var iframeContainer = "centeredText-module--iframe-container--a3c72";
export var indicationSection = "centeredText-module--indication-section--c7afd";
export var left = "centeredText-module--left--7fb60";
export var overlay = "centeredText-module--overlay--f6f39";
export var removeMobile = "centeredText-module--remove-mobile--a267a";
export var responsiveIframe = "centeredText-module--responsive-iframe--920bc";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "centeredText-module--secondLevelHero-module--banner__subTitle--8b2b5--cb259";
export var singleTimestamp = "centeredText-module--single-timestamp--580a3";
export var slider = "centeredText-module--slider--0c056";
export var small = "centeredText-module--small--a7a40";
export var standard = "centeredText-module--standard--b0fe2";
export var switcher = "centeredText-module--switcher--e6c71";
export var timestamps = "centeredText-module--timestamps--68b95";
export var titleText = "centeredText-module--titleText--772d6";
export var videoArea = "centeredText-module--videoArea--51cb4";
export var videoResource = "centeredText-module--video-resource--35cf6";
export var videoTime = "centeredText-module--videoTime--26bdc";
export var video__iframe = "centeredText-module--video__iframe--d5167";
export var yell = "centeredText-module--yell--6fd7a";