// extracted by mini-css-extract-plugin
export var active = "callToActions-module--active--790fe";
export var bigWhite = "callToActions-module--big-white--a6e95";
export var blue = "callToActions-module--blue--b61b2";
export var blueBg = "callToActions-module--blue-bg--14460";
export var callToAction = "callToActions-module--callToAction--b3a20";
export var callToAction__content = "callToActions-module--callToAction__content--9ec06";
export var callToAction__content__inner = "callToActions-module--callToAction__content__inner--845d7";
export var callToAction__content__title = "callToActions-module--callToAction__content__title--63866";
export var callToAction__image = "callToActions-module--callToAction__image--f6f46";
export var callToActions = "callToActions-module--callToActions--5cf91";
export var center = "callToActions-module--center--3d999";
export var cookie__customise = "callToActions-module--cookie__customise--59026";
export var cookie__customise__buttons = "callToActions-module--cookie__customise__buttons--edef6";
export var cookie__customise__buttons__allow = "callToActions-module--cookie__customise__buttons__allow--557a7";
export var cookie__customise__buttons__disable = "callToActions-module--cookie__customise__buttons__disable--0e4fb";
export var cookie__customise__buttons__save = "callToActions-module--cookie__customise__buttons__save--973a1";
export var cookie__customise__close = "callToActions-module--cookie__customise__close--2f292";
export var cookie__customise__content = "callToActions-module--cookie__customise__content--02848";
export var cookie__customise__content__title = "callToActions-module--cookie__customise__content__title--2a42c";
export var cookie__customise__overlay = "callToActions-module--cookie__customise__overlay--0c9e8";
export var cookie__popup = "callToActions-module--cookie__popup--f519e";
export var cookie__popup__button = "callToActions-module--cookie__popup__button--5519c";
export var cookie__popup__close = "callToActions-module--cookie__popup__close--17af6";
export var cookie__popup__content = "callToActions-module--cookie__popup__content--6b1ce";
export var cookie__popup__text = "callToActions-module--cookie__popup__text--dfffd";
export var decreaseHeight = "callToActions-module--decrease-height--4cc4a";
export var embed_responsive = "callToActions-module--embed_responsive--4be77";
export var embed_responsive_16by9 = "callToActions-module--embed_responsive_16by9--ee9f0";
export var embed_responsive_item = "callToActions-module--embed_responsive_item--c103e";
export var flexUl = "callToActions-module--flex-ul--2bbc4";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "callToActions-module--fullWidthCta-module--fullWidthCta--fdc73--32e4c";
export var fullWidthTextModuleIntroText2b0e4 = "callToActions-module--fullWidthText-module--introText--2b0e4--0b050";
export var header = "callToActions-module--header--1a85d";
export var iframeContainer = "callToActions-module--iframe-container--33155";
export var indicationSection = "callToActions-module--indication-section--badfb";
export var left = "callToActions-module--left--97ac3";
export var overlay = "callToActions-module--overlay--e4f95";
export var removeMobile = "callToActions-module--remove-mobile--a8210";
export var responsiveIframe = "callToActions-module--responsive-iframe--40798";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "callToActions-module--secondLevelHero-module--banner__subTitle--8b2b5--c2cfd";
export var singleTimestamp = "callToActions-module--single-timestamp--e203a";
export var slider = "callToActions-module--slider--deed8";
export var small = "callToActions-module--small--dc0c9";
export var standard = "callToActions-module--standard--c08b3";
export var switcher = "callToActions-module--switcher--112a6";
export var timestamps = "callToActions-module--timestamps--38efb";
export var videoArea = "callToActions-module--videoArea--8479d";
export var videoResource = "callToActions-module--video-resource--42b0d";
export var videoTime = "callToActions-module--videoTime--7f945";
export var video__iframe = "callToActions-module--video__iframe--15025";
export var yell = "callToActions-module--yell--17896";