import React from "react"
import * as styles from "./callToActions.module.scss"
import Button from "../button/button"

function CallToActions({ data }) {

  const CallToActions = data.map(callToAction => {

    let ButtonData = {
      slug: callToAction.buttonDestination,
      text: callToAction.buttonText
    }

    return (
      <div className={styles.callToAction} key={callToAction.text.text}>
        <div className={styles.callToAction__content}>
          <div className={styles.callToAction__content__inner}>
            <div className={styles.callToAction__content__title} dangerouslySetInnerHTML={{ __html: callToAction.text.childMarkdownRemark.html }} />
            <Button className={styles.callToAction__button} data={ButtonData} />
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className={styles.callToActions}>
      {CallToActions}
    </div>
  )

}

export default CallToActions